// import Vue from 'vue'
// Vue.use(VueRouter)
import {createRouter,createWebHashHistory} from 'vue-router'

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/pages/home.vue'),
  },
  {
    path: '/chateau',
    name: 'chateau',
    component: () => import('@/pages/chateau.vue'),
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/pages/product.vue'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/pages/aboutUs.vue'),
  },
]

// const originalPush = VueRouter.prototype.push

// VueRouter.prototype.push = function push(location){
//   return originalPush.call(this,location).catch(err => err)
// }

const router = new createRouter({
  history: createWebHashHistory(),
  routes,
  // scrollBehavior () {
  //   return { x: 0, y: 0 }
  // }
})

export default router
