<!--  -->
<template>
  <div
    class="head"
    :class="scrollClass ? 'scrollHover' : ''"
    :style="'height:' + (screenWidth < 1250 && scrollClass ? '120px' : '')"
  >
    <div class="Information"></div>
    <div class="nav">
      <!-- <div class="left">
        <a href="#">联系电话：400-8888-888</a>
      </div> -->
      <!-- <div class="right">
        <a href="#">登录</a>
      </div> -->
      <div
        class="nav_logo_img"
        v-if="(screenWidth > 1250 && !scrollClass) || screenWidth < 1250"
      >
        <!-- <router-link to="/">
        <img
          src="https://res.gucci.cn/images/common/gucci-logo@2x.png"
          alt=""
        />
      </router-link> -->
      <h3 class="domeName">纳帕酒庄</h3>
      </div>
      <ul
        class="nav_ul"
        :style="
          'margin-top:' + (screenWidth > 1250 && scrollClass ? '5px' : '')
        "
      >
        <li
          class="nav_li"
          v-for="(item, index) of navArr"
          :key="index"
          @click="jump(item.route, index)"
        >
          <a
            href="javascript:;"
            class="nav_a"
            :style="
              'height:' + (screenWidth > 1250 && scrollClass ? '45px' : '51px')
            "
          >
            <span>{{ item.name }}</span>
          </a>
          <div class="nav_sub_menu">
            <div class="nav_sub_menu_slot">
              <div class="nav_sub_menu_slot_container"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    // 监听页面宽度
    screenWidth: {
      handler(val) {
        if (val < 1550) {
          // console.log("屏幕宽度小于1550px");
        } else {
          // console.log("屏幕宽度大于1550px");
        }
      },
    },
  },
  data() {
    return {
      navArr: [
        {
          name: "关于我们",
          route: "/home",
        },
        {
          name: "成为会员",
          route: "/chateau",
        },
        {
          name: "联系方式",
          route: "/product",
        },
        {
          name: "napa365使命",
          route: "/aboutUs",
        },
      ],
      scrollClass: false, //滚动动态样式
      screenWidth: null,
    };
  },
  mounted() {
    // 获取滚动距离
    window.addEventListener("scroll", this.scrollHandle);
    // 获取页面宽度
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.scrollClass = top > 100 ? true : false;
      // console.log(this.scrollClass, this.screenWidth);
    },
    jump(route, index) {
      console.log(index);
      this.$router.push({
        path: route,
        // query: {
        //   navName: route,
        //   navId: index,
        // },
      });
      // this.$router.push({
      //   name: "goodsList",
      //   params: {
      //     navName: this.navArr[index],
      //     navId: index,
      //   },
      // });
    },
  },
};
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
  color: #e5dfd9;
  letter-spacing: 1.34px;
  line-height: 40px;
}
a:hover {
  color: #999 !important;
}
.domeName {
  font-size: 30px;
  color: #f5f5f5;
  margin: 0;
  margin-bottom: 15px;
}
// 头部样式
.head {
  width: 100vw;
  height: 130px;
  font-size: 16px;
  position: fixed;
  left: 0;
  top: 0;
  // overflow: hidden;
  box-sizing: border-box;
  background: linear-gradient(180deg, #23201e 0, rgba(35, 32, 30, 0));
  transition: all 0.2s ease-out;
  z-index: 1000;
  &:hover {
    transition: all 0.2s ease-out;
    background-color: #1b1b1b;
  }
  .nav {
    width: 100%;
    padding-top: 10px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
 
    .left {
      position: absolute;
      left: 0;
      min-width: 250px;
      text-align: right;
      a {
        padding: 0 5px;
      }
    }
    .right {
      min-width: 120px;
      position: absolute;
      right: 0;
      a {
        padding: 0 5px;
      }
    }
    .nav_logo_img {
      display: block;
      width: 160px;
      padding: 16px 0 2px;
      margin: 0 auto;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .nav_ul {
      width: 100%;
      height: 100%;
      padding: 0;
      list-style: none;
      margin: 0 auto;
      text-align: center;
      .nav_li {
        height: 100%;
        font-family: "FuturaLT-Light", "GucciChinese";
        display: inline-block;
        // padding: 0 15px;
        &:hover > .nav_a::before {
          display: block;
        }
        &:hover > .nav_sub_menu {
          height: 100px;
          opacity: 1;
        }
        .nav_a {
          display: block;
          font-family: Futura-LT-Medium, GucciChinese;
          line-height: 30px;
          position: relative;
          z-index: 19;
          height: 51px;
          padding: 0 35px;
          letter-spacing: 1.34px;
          color: #e5dfd9;
          text-align: center;
        }
        // hover三角形
        .nav_a::before {
          display: none;
          position: absolute;
          bottom: 14px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          text-align: center;
          content: "";
          border: 0 solid transparent;
          border-right-width: 6px;
          border-bottom-width: 6px;
          border-bottom-color: inherit;
          border-left-width: 6px;
        }
        // 隐藏下拉框
        .nav_sub_menu {
          display: none;
          width: 100%;
          height: 0;
          position: absolute;
          z-index: 1;
          top: 100%;
          left: 0;
          overflow: hidden;
          padding: 0 15px;
          opacity: 0;
          background-color: transparent;
          box-sizing: border-box;
          transition: all 0.2s ease-out;
          .nav_sub_menu_slot {
            height: 100%;
            padding: 20px 15px;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.95);
            .nav_sub_menu_slot_container {
              max-width: 1296px;
              margin: 0 auto;
              overflow: hidden;
              font-size: 0;
              text-align: center;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
// 滚动页眉样式
.scrollHover {
  height: 62px;
  transition: all 0.2s ease-out;
  background-color: #1b1b1b;
  animation-name: toScroll;
}
@media screen and (min-width: 1340px) {
}
</style>
