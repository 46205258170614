<template>
  <MyNavigation></MyNavigation>
  <router-view></router-view>
    <!-- <MyFooter></MyFooter> -->
</template>

<script>
import MyNavigation from "@/components/myNavigation.vue";
// import MyFooter from "../components/myFooter.vue";

export default {
  components: { MyNavigation },
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 1000px;
  /* margin-top: 60px; */
}
body {
  margin: 0;
  padding: 0;
}
</style>
